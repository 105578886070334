var Cameraland = Cameraland || {};

Cameraland.StockAlert = class {

    constructor(options) {
        this.initAll = this.initAll.bind(this);
        this.initAll();

        $j('#out-of-stock-toggle').click(function(e){
            $j('.out-of-stock-container').show();
            this.hide();
        });
    }

    initAll() {

        // Pdp configurable stock changes
        $j(window).on('stockAlertProductChanged', (e) => {

            var stockStatusContainer = $j('.availability');
            if (e.stockQty == 0) {
                $j('#out-of-stock-toggle').show();
                $j('#stocknotify-product-id').val(e.productId);
                $j('.add-to-cart-buttons').hide();
                $j('.location-stock').hide();
                $j('.button-pickup').hide();

                stockStatusContainer.removeClass('in-stock');
                stockStatusContainer.addClass('out-of-stock');
                stockStatusContainer.find('.value').html('Tijdelijk uitverkocht');
            } else {
                $j('#out-of-stock-toggle').hide();
                $j('.out-of-stock-container').hide();

                $j('#stocknotify-product-id').val(e.productId);
                $j('.add-to-cart-buttons').show();
                $j('.location-stock').show();
                $j('.button-pickup').show();

                stockStatusContainer.removeClass('out-of-stock');
                stockStatusContainer.addClass('in-stock');
                stockStatusContainer.find('.value').html('Op voorraad');
            }
        });
    }
};

$j(function () {
    new Cameraland.StockAlert();
});

